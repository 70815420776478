const ErrorPage = () => {
    return (
    <>
    <h1>Oops! That URL does not exist!</h1>
    <h4><a href="/channels">Click here to return to Channel List</a></h4>
    </>

    )
}

export default ErrorPage
